<template>
<div>
    <b-card class="mb-2">
        <b-card-header>
            <h3>View Vehicle</h3>
            <span class="float-right">
                <b-button
                v-if="!editView"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                title="Edit"
                @click="openEditView"
                >
                    <feather-icon
                    icon="EditIcon"
                    svg-classes="h-10 w-10"
                    />
                </b-button>
                <b-button
                    v-else
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon"
                    title="Update"
                    @click="save"
                >
                    <feather-icon
                    icon="CheckIcon"
                    svg-classes="h-10 w-10"
                    />
                </b-button>
            </span>
        </b-card-header>
        <b-card-text>
            <div class="table-responsive-md">
                <table class="table table-borderless">
                    <tr>
                        <td>Wheel Layout</td>
                        <td v-if="!editView">
                            {{ wheel_layout }}
                        </td>
                        <td v-else>
                            <custom-autopopulate
                                id="wheel_layout"
                                module="Wheellayouts"
                                retrieve="full_object"
                                :value="wheelId"
                                @changed="wheelSelected"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Branch</td>
                        <td v-if="!editView">
                            {{ branch_name }}
                        </td>
                        <td v-else>
                            <custom-autopopulate
                                id="branch_name"
                                module="branches"
                                retrieve="full_object"
                                label="name"
                                :value="branchId"
                                @changed="branchSelected"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Customer</td>
                        <td v-if="!editView">
                            {{ client_name }}
                        </td>
                        <td v-else>
                            <b-form-input
                                :id="field.value"
                                v-model="item"
                                :value="clientId"
                                :disabled="!showClient"
                                maxlength="250"
                                @input="resClient(showClient)"
                            />
                            <b-list-group v-if="filtersearches && listview">
                                <b-list-group-item
                                    v-for="filtersearch in filtersearches"
                                    :key="filtersearch.id"
                                    @click="setclient(filtersearch.client_name, filtersearch.id)"
                                >
                                    {{ filtersearch.client_name }}
                                </b-list-group-item>
                            </b-list-group>
                        </td>
                    </tr>
                    <tr>
                        <td>Fleet Number</td>
                        <td v-if="!editView">
                            {{ fleet_number }}
                        </td>
                        <td v-else>
                            <b-form-input
                                v-model="fleet_number"
                                placeholder="Fleet Number"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Registration Number</td>
                        <td v-if="!editView">
                            {{ registration_number }}
                        </td>
                        <td v-else>
                            <b-form-input
                                v-model="registration_number"
                                placeholder="Registration Number"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Odometer Reading</td>
                        <td v-if="!editView">
                            {{ current_odometer_reading }}
                        </td>
                        <td v-else>
                            <b-form-input
                                v-model="current_odometer_reading"
                                placeholder="Odometer Reading"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td>Active Status</td>
                        <td>
                            <b-form-checkbox
                                v-model="hasActiveStatus"
                                switch
                                size="md"
                                @change="hasActiveStatusChanged"
                            />
                        </td>
                    </tr>
                </table>
            </div>
        </b-card-text>
    </b-card>
    <b-card class="mb-1">
        <b-card-header>
            <h3>Car Image</h3>
            <span class="float-right">
                <b-button
                    v-if="!editImageView"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon"
                    title="Edit"
                    @click="openImageEditView"
                >
                    <feather-icon
                        icon="EditIcon"
                        svg-classes="h-10 w-10"
                    />
                </b-button>
                <b-button
                    v-else
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon"
                    title="Update"
                    @click="saveImage"
                >
                    <feather-icon
                        icon="CheckIcon"
                        svg-classes="h-10 w-10"
                    />
                </b-button>
            </span>
        </b-card-header>
        <b-card-text>
            <div class="container mt-2 ml-1">
            <CoolLightBox
            :items="imageLink"
            :index="index"
            @close="index = null"
            />
                <div
                    v-if="!editImageView"
                    class="carousel_block"
                    >
                    <carousel
                        ref="carousel"
                        :per-page="3"
                        :pagination-enabled="false"
                        :navigation-enabled="true"
                        :navigation-click-target-size="4"
                        :loop="true"
                        :per-page-custom="[
                            [320, 1],
                            [768, 1],
                            [1199, 3],
                        ]"
                    >
                        <slide
                            v-for="(imgLink, imageIndex) in imageLink"
                            :key="imageIndex"
                        >
                            <div
                                class="card m-2"
                                style="cursor: pointer;"
                                @click="index = imageIndex"
                            >
                                <img
                                    :src="imgLink"
                                    alt=""
                                    class="card-img-top"
                                >
                            </div>
                        </slide>
                    </carousel>
                </div>
                <b-form-file
                    v-else
                    v-model="image"
                    :state="Boolean(image)"
                    placeholder="Choose a file"
                    accept="image/*"
                />
            </div>
        </b-card-text>
    </b-card>
    <GoBack />
</div>
</template>

<script>
import * as imageConversion from 'image-conversion';
import {
    BCard,
    BCardText,
    BButton,
    BCardHeader,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormFile,
    BListGroup,
    BListGroupItem,
} from 'bootstrap-vue';
import { Carousel, Slide } from 'vue-carousel'
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import CustomAutopopulate from '../../common/components/CustomAutopopulate';
import GoBack from '../../common/goBack.vue';

export default {
    components: {
        BCard,
        BCardText,
        BCardHeader,
        BFormInput,
        BButton,
        BFormCheckbox,
        BFormFile,
        CustomAutopopulate,
        BListGroup,
        BListGroupItem,
        Carousel,
        Slide,
        CoolLightBox,
        GoBack,
    },
    directives: {
        Ripple,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            editView: false,
            fleet_number: '',
            clientId: 0,
            client_name: '',
            client: '',
            registration_number: '',
            current_odometer_reading: '',
            hasActiveStatus: false,
            editImageView: false,
            image: null,
            imageLink: [],
            imgsrc: [],
            showClient: '',
            branchId: 0,
            item: '',
            field: '',
            listview: false,
            wheel_layout: '',
            slide: 0,
            sliding: null,
            branch_name: '',
            index: null,
        };
    },
    created() {
        this.getData();
    },
    methods: {
        openEditView() {
            this.editView = true;
        },
        callServer(data) {
            const params = { ...data };
            this.$helper.showLoading();
            this.$axios
                .post(`vehicle/update/${this.id}`, params)
                .then(resp => {
                    this.$helper.hideLoading();
                    if (resp.data) {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Vehicle Updated',
                                icon: 'CheckIcon',
                                variant: 'Primary',
                            },
                        });
                        this.editView = false;
                    }
                })
                .catch(err => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
        },
        save() {
            this.callServer({
                client_id: this.clientId,
                client: this.client,
                wheel_layout_id: this.wheelId,
                fleet_number: this.fleet_number,
                registration_number: this.registration_number,
                current_odometer_reading: this.current_odometer_reading,
                is_active: this.hasActiveStatus,
                branch_id: this.branchId,
            });
        },
        branchSelected(value) {
            if (value) {
                this.branch_name = value.name;
                this.showClient = value.id;
                this.branchId = value.id;
            }
        },
        wheelSelected(value) {
            if (value) {
                this.wheelId = value.id;
                this.wheel_layout = value.name;
            }
        },
        getData() {
            this.$helper.showLoading();
            this.$axios
                .get(`/vehicles/show/${this.id}`)
                .then(resp => {
                    this.$helper.hideLoading();
                    console.log('Vehicle Response', resp.data)
                    if (resp.data) {
                        this.fleet_number = resp.data.results.result.fleet_number;
                        this.registration_number = resp.data.results.result.registration_number;
                        this.current_odometer_reading = resp.data.fields.current_odometer_reading.value.reading;
                        this.registration_number = resp.data.results.result.registration_number;
                        this.client_name = resp.data.fields.client.value.name;
                        this.clientId = resp.data.results.result.client_id;
                        this.client = resp.data.fields.client_id.value.name;
                        this.branchId = resp.data.fields.branch_id.value;
                        if (resp.data.fields.branch_name) {
                            this.branch_name = resp.data.fields.branch_name.value;
                        }
                        this.wheel_layout = resp.data.fields.wheel_layout_name.value;
                        this.wheelId = resp.data.results.result.wheel_layout_id;
                        this.wheel = resp.data.fields.wheel_layout_id.value.name;
                        this.imageLink = resp.data.fields.image.value;
                        if (resp.data.results.result.is_active) {
                            this.hasActiveStatus = true;
                        } else {
                            this.hasActiveStatus = false;
                        }
                    } else {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: resp.data.data.message,
                                icon: 'AlertCircleIcon',
                                variant: 'danger',
                            },
                        });
                    }
                })
                .catch(error => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                    console.error(error);
                });
        },
        hasActiveStatusChanged(value) {
            this.callServer({
                is_active: value,
            });
        },
        openImageEditView() {
            this.editImageView = true;
        },
        async saveImage() {
            this.editImageView = false;
            if (this.image) {
                const formData = new FormData();
                let header = null;
                header = {
                    headers: {
                        'content-type': 'multipart/form-data',
                    },
                };
                const compressed = await imageConversion.compressAccurately(this.image, 500)
                formData.append('image', compressed);
                this.update(formData, header);
                this.image = null;
            }
        },
        update(params, header) {
        this.$helper.showLoading();
        this.$axios
            .post(`vehicle/update/${this.id}`, params, header)
            .then(resp => {
                this.$helper.hideLoading();
                if (resp.status) {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Data Updated',
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    });
                    this.getData();
                } else {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: resp.data.data.message,
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                }
            })
            .catch(error => {
                this.$helper.hideLoading();
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Oops! Something went wrong',
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                    },
                });
                console.error(error);
            });
        },
        resClient(branchId) {
            if (this.item.length > 3) {
                this.listview = true;
                this.callClient({
                    search: this.item,
                    branch_id: branchId,
                });
            }
        },
        callClient(data) {
            const params = { ...data };
            this.$helper.showLoading();
            this.$axios
                .post(`vehicles/getClientData`, params)
                .then(resp => {
                    this.$helper.hideLoading();
                    if (resp.data) {
                        this.field = resp.data;
                        this.clientfilter();
                    }
                })
                .catch(err => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                });
        },
        setclient(cname, cid) {
            this.clientId = cid;
            this.item = cname;
            this.client_name = cname;
            this.field.value = cid;
            this.listview = false;
        },
        clientfilter() {
            this.items = this.field;
            this.filtersearches = this.items.filter(item => item.client_name.toLowerCase().includes(this.item.toLowerCase()));
        },
    },
};
</script>
<style lang="scss">
td:nth-child {
    width: 30rem;
}
.list-group {
    max-height: 150px;
    overflow-y: scroll;
}
</style>
