<template>
<b-row>
    <b-col
        xl="8"
        lg="8"
        md="12"
        sm="12"
    >
        <general-info
        :id="id"
        />
    </b-col>
</b-row>
</template>

<script>
import {
    BRow, BCol,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import GeneralInfo from './components/generalInfo';
export default {
    components: {
        BRow,
        BCol,
        GeneralInfo,
    },
    data() {
        return {
            id: 0,
        };
    },
    created() {
        const routeId = this.$route.params.id;
        this.id = parseInt(routeId, 10);
        this.fetchData();
    },
    methods: {
        fetchData() {
            this.$helper.showLoading();
            this.$axios
                .get(`/vehicles/show/${this.id}`)
                .then(resp => {
                    this.$helper.hideLoading();
                })
                .catch(error => {
                    this.$helper.hideLoading();
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Oops! Something went wrong',
                            icon: 'AlertCircleIcon',
                            variant: 'danger',
                        },
                    });
                    console.error(error);
                });
        },
    },
};
</script>
